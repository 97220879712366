import React, { useState } from 'react'
import Video from '../../../static/images/df-nova-localizacao.mp4'
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa'

const VideoSection = ({ title1, title2, title3, description, button }) => {
  const [isMute, setIsMute] = useState(true)
  return (
    <div>
      <div className="relative max-w-1363 mx-auto">
        <video
          className="mx-auto video-container"
          autoPlay
          muted={isMute}
          loop
          id="myVideo"
        >
          <source src={Video} type="video/mp4" />
        </video>
        {isMute && (
          <FaVolumeMute
            className="h-8 w-8 mr-4 mb-3 absolute bottom-0 right-0 text-white cursor-pointer"
            onClick={() => setIsMute(!isMute)}
          />
        )}
        {!isMute && (
          <FaVolumeUp
            className="h-8 w-8 mr-4 mb-3 absolute bottom-0 right-0 text-white cursor-pointer"
            onClick={() => setIsMute(!isMute)}
          />
        )}
      </div>
      <div className="max-w-screen-xl mx-auto justify-between items-start md:items-center flex flex-col md:flex-row px-4 py-16 md:px-16">
        <div className="flex-1 flex flex-col">
          <h1 className="text-xl sm:text-3xl font-bold text-primary">
            {title1}
          </h1>
          <h2 className="text-xl sm:text-3xl font-bold text-primary">
            {title2}
          </h2>
          <h2 className="text-xl sm:text-3xl font-bold text-primary">
            {title3}
          </h2>
        </div>
        <h3 className="mreaves flex-1 text-base sm:text-lg text-white py-5">
          {description}
        </h3>
      </div>
    </div>
  )
}
export default VideoSection
